import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import quoteImage from "./quote.jpg";
import { Grid, useMediaQuery } from '@mui/material';

export default function Orunmila() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container sx={{ mt: 7 }} id="orunmila">
            <Grid item xs={12} justifyContent="center" sx={{ display: 'flex' }}>
                <Card sx={{ display: 'flex', width: 930, flexDirection: matches ? 'row' : 'column' }} elevation={0}>
                    {!matches && <CardMedia
                        component={'div'}
                        sx={{ width: "100%", height: 350, backgroundPosition: 'middle' }}
                        image={quoteImage}
                        alt="Refran"
                    />}

                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: matches ? 22 : 4, mr: matches ? 5 : 0 }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant="h4" sx={{ textAlign: "center" }} >
                                "Olofin no le abre las puertas a aquel que antes no se las abrió a un hermano."
                            </Typography>
                            <Typography variant="h6" sx={{ textAlign: "center", mt: 3 }} >
                                (refrán del odun iwori juani)
                            </Typography>
                        </CardContent>

                    </Box>
                    {matches && <CardMedia
                        component={'div'}
                        sx={{ width: 1200, height: 600 }}
                        image={quoteImage}
                        alt="Refran"
                    />}

                </Card>
            </Grid>
        </Grid>
    );
}