
import Welcome from "./welcome/Welcome";
// import PowerGiving from "./iniciaciones/Iniciaciones";
import Religion from "./religion/Religion";
import Babalawo from "./orunmila/Orunmila";
import Footer from "./footer/Footer";
import ScrollTop from "./ScrollTop";
import AppBar from "./AppBar";
import Questions from "./questions/Questions";
import Quote from "./quote/Quote"
function App() {
  return (
    <>
      <AppBar />
      <Welcome />
      <Questions />
      <Religion />
      {/* <PowerGiving /> */}
      <Babalawo />
      <Quote/>
      <Footer />
      <ScrollTop topComponentId="app-bar" />
    </>
  );
}

export default App;
