import { Box, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery } from "@mui/material";
// import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import questions from "./dudas.jpg";
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';

const dudas = [{
    question: '¿Cuánto dura la consulta?',
    answer: `La consulta dura una hora y media aproximadamente.`
}, {
    question: '¿Qué horario tienen las consultas?',
    answer: `Desde las 9:00 de la mañana, hasta las 16:30, todos los días de la semana.`
}, {
    question: '¿Qué sistema adivinatorio es usado en las consultas?',
    answer: `El oráculo de ifa, sistema adivinatorio ancestral utilizado en la
    religión yoruba. Este oráculo consta de 256 signos, que el babalawo
    debe interpretar durante el proceso adivinatorio. Para que un
    babalawo llegue a dominar a cabalidad este oráculo, se necesitan
    varios años de estudio.`
}, {
    question: '¿Puedo preguntar todo lo que quiera?',
    answer: `Puede hacer cuantas preguntas desee, con total libertad.`
}];

export default function Questions() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container sx={{ mt: 7 }} id="questions">
            <Grid item xs={12} justifyContent="center" sx={{ display: 'flex' }}>
                <Card sx={{ display: 'flex', width: 930, flexDirection: 'column' }} elevation={0}>
                    <CardMedia
                        component={matches ? "img" : "div"}
                        sx={matches ? { height: 320 } : { height: 320 }}
                        image={questions}
                        alt="Dudas frecuentes"
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto', position: 'relative' }}>
                            <Typography variant={matches ? "h3" : "h4"} sx={{ pt: 2, textAlign: "center" }}>
                                DUDAS FRECUENTES
                            </Typography>
                            <Box sx={{
                                position: 'absolute',
                                width: '400px',
                                height: '346px',
                                left: matches ? '355px' : '166px',
                                top: '242px',
                                background: 'radial-gradient(72.77% 72.77% at 50% 50%, #d5a98514  0%, rgba(255, 255, 255, 0) 100%)',
                                borderRadius: '50%',
                                WebkitFilter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
                            }} />

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >

                                {dudas.map(({ question, answer }, index) => (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 4 }} key={index}>
                                        {/* <LabelImportantIcon />  */}
                                        <Typography variant="h6" color="text.primary" component="div" sx={{ ml: 1 }} >
                                            - {question}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" component="div" sx={{ ml: 1, mt: 2 }} >
                                            {answer}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start", mt: 6 }}>
                                <CheckCircleIcon color="success" fontSize="large" />
                                <Typography variant="h6" color="text.primary" component="div" sx={{ ml: 3 }} >
                                    Llámeme o escríbame  y le explicaré  detalladamente, según el caso.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start", mt: 2 }}>
                                <DangerousIcon sx={{ color: "#C70039" }} fontSize="large" />
                                <Typography variant="h6" color="text.primary" component="div" sx={{ ml: 3 }} >
                                    No me llame, si su objetivo es realizar ceremonias con el fin de dañar a otras personas.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", mt: 6, justifyContent: "space-around" }}>

                                <Typography variant="h6" color="text.primary" component="div"
                                    sx={{ ml: 1, fontWeight: 'bold' }} >
                                    &quot;El que sabe no muere como el que no sabe&quot; (Obara Meji)
                                </Typography>
                            </Box>
                        </CardContent>

                    </Box>

                </Card>

            </Grid>

        </Grid>

    )
}