import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import welcome from "./orunmila.webp";
import { Grid, Toolbar, useMediaQuery } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Bold from '../Bold';

export default function Welcome() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <div id="inicio">
            <Toolbar id="app-bar"/>
            <Grid container id="inicio" sx={{
                mt: {
                    sm: 0,
                    md: 5
                }, mb: 7
            }}>
                <Grid item xs={12} justifyContent="center" sx={{ display: 'flex' }}>
                    <Card sx={{ display: 'flex', width: 930, flexDirection: matches ? 'row' : 'column' }} elevation={0}>
                        {!matches && <CardMedia
                            component="div"
                            sx={{
                                height: {
                                    xs: 400,
                                    sm: 500
                                },
                                backgroundPosition: 'top'
                            }}
                            image={welcome}
                            alt="Babalawo en Barcelona"
                        />}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto', mr: matches ? 4 : 0 }}>
                                <Typography variant={matches ? "h3" : "h4"} sx={{ pt: matches ? 3 : 2, textAlign: matches ? "left" : "center" }}>
                                    BIENVENIDO A BABALAWO EN BARCELONA
                                </Typography>
                                <Typography variant="h6" sx={{ pt: matches ? 4 : 2, textAlign: matches ? "left" : "center" }}>
                                    CONSULTAS EN BARCELONA
                                </Typography>
                                <Typography variant="subtitle1" sx={{ pt: 4, textAlign: "left" }}>
                                    Babalawo Alexis Omo Òbàtála
                                </Typography>

                                <Typography variant="body1" color="text.secondary" component="div" sx={{ pt: 4 }}>
                                    En mi casa de Ifa lo <Bold text="primero son las personas." />
                                    <br /><br />
                                    Cualquiera que sienta la necesidad de consultarse con un Babalawo para <Bold text="descubrir
                                el origen y la solución de sus problemas"/>, encontrará en el oráculo de Ifa la
                                    respuesta que espera. El Babalawo es el sacerdote mayor de la religión Yoruba y el
                                    único facultado para interpretar el oráculo de ifa. Su misión es <Bold text="ayudar a todo el que
                                acuda a su consulta"/> (pertenezca o no la religión).
                                    <br /><br />
                                    Las consultas deben hacerse estando el interesado presente. Si por motivos de
                                    salud o lejanía el consultado no pudiera acudir a casa del Babalawo, éste
                                    podría realizar una consulta inicial a distancia, con el fin de encauzar su
                                    situación.
                                </Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", mt: 4 }}>
                                    <EmailIcon />
                                    <Typography variant="h6" color="text.primary" component="div" sx={{ ml: 1 }} >
                                        babalawoenbarcelona@gmail.com
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", mt: 1 }}>
                                    <WhatsAppIcon />
                                    <Typography variant="h6" color="text.primary" component="div" sx={{ ml: 1 }} >
                                        +34 626 596 124
                                    </Typography>
                                </Box>

                            </CardContent>

                        </Box>
                        {matches ? <CardMedia
                            component="img"
                            sx={{ height: 940 }}
                            image={welcome}
                            alt="Babalawo en Barcelona"
                        /> : null}
                    </Card>
                </Grid>
            </Grid>
        </div>

    );
}