import React from "react";
import { useTheme } from '@mui/material/styles';
import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography, useMediaQuery, useScrollTrigger } from "@mui/material";
import { Box } from "@mui/system";
import MenuIcon from '@mui/icons-material/Menu';

function ElevationScroll({ children }) {

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

function isInViewport(el) {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
}

function MainAppBar() {
    const theme = useTheme();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [links, setLinks] = React.useState([
        { caption: 'Inicio', anchorId: "inicio", isInViewport: true },
        { caption: 'Dudas Frecuentes', anchorId: "questions", isInViewport: false },
        { caption: 'La Religión', anchorId: "religion", isInViewport: false },
        // { caption: 'Iniciaciones', anchorId: "iniciaciones", isInViewport: false },
        { caption: 'Orunmila', anchorId: "orunmila", isInViewport: false },
        { caption: 'Contacto', anchorId: "contact", isInViewport: false }
    ]);

    const upLg = useMediaQuery(theme.breakpoints.up('lg'));

    const throttleTimeout = React.useRef(null)

    const callBack = React.useCallback(() => {
        for (let i = links.length - 1; i >= 0; i--) {
            const link = links[i];
            if (isInViewport(document.querySelector(`#${link.anchorId}`))) {
                setLinks(links.map(link2 => ({
                    ...link2,
                    isInViewport: link2.anchorId === link.anchorId
                })));
                break;
            }
        }

        throttleTimeout.current = null
    }, [links]);

    React.useLayoutEffect(() => {
        const handleScroll = () => {
            if (throttleTimeout.current === null) {
                throttleTimeout.current = setTimeout(callBack, 200)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [callBack]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleLinkClick = (link) => {
        if (!link?.anchorId) {
            return document.location.href = "/";
        }
        const anchor = (document).querySelector(
            `#${link.anchorId}`,
        );
        if (anchor) {
            // setTimeout(() => anchor.scrollIntoView({
            //     behavior: 'smooth',
            //     block: 'start',
            // }), 30);

            setTimeout(() => {
                window.scrollTo({
                    top: anchor.getBoundingClientRect().top + window.pageYOffset - (link.offset || 70),
                    behavior: 'smooth'
                });
            }, 30)
        }
    }

    return (
        <>
            <ElevationScroll>
                <AppBar >
                    <Toolbar>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {links.map((link, index) => (
                                    <MenuItem key={index} onClick={() => {
                                        handleCloseNavMenu();
                                        handleLinkClick(link);
                                    }} selected={link.isInViewport}>
                                        <Typography textAlign="center">{link.caption}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {links.map((link, index) => (
                                <Button
                                    size={upLg ? "medium" : "small"}
                                    key={index}
                                    onClick={() => handleLinkClick(link)}
                                    sx={{
                                        my: 2,
                                        color: link.isInViewport ? 'white' : '#B3B6B7',
                                        display: 'block',
                                        "&:hover": {
                                            color: "white"
                                        }
                                    }}
                                >
                                    {link.caption}
                                </Button>
                            ))}
                        </Box>
                        <Typography variant={upLg ? "h5" : "button"} onClick={handleLinkClick}
                            sx={{
                                '&:hover': {
                                    cursor: "pointer"
                                }
                            }}
                        >
                            BABALAWO EN BARCELONA
                        </Typography>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>

        </>
    )
}

export default MainAppBar;