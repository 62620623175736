import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import babalawo from "./orunmila.jpg";
import { Grid, useMediaQuery } from '@mui/material';

export default function Orunmila() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container sx={{ mt: 5 }} id="orunmila">
            <Grid item xs={12} justifyContent="center" sx={{ display: 'flex' }}>
                <Card sx={{ display: 'flex', width: 930, flexDirection: matches ? 'row' : 'column'  }} elevation={0}>
                    <CardMedia
                        component={'div'}
                        sx={matches ? { width: "2000px" } : { height: 450 }}
                        image={babalawo}
                        alt="La religion"
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: matches ? 5 : 0 }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant={matches ? "h3" : "h4"} sx={{ pt: 2, textAlign: matches ? "left" : "center" }}>
                                ORUNMILA
                            </Typography>
                            {/* <Typography variant="body1" color="text.secondary" sx={{ pt: 4, textAlign: "left" }}>

                                El Babalawo (padre de los secretos), es el sacerdote mayor de la
                                religión Yoruba, conocida en Cuba como “Santería “. Es el poseedor
                                de todos los secretos de esta fe milenaria. Único autorizado para la
                                interpretación del Oráculo de Ifa, el más complejo de los sistemas
                                adivinatorios de la religión Yoruba y uno de los más complejos del
                                mundo.
                                <br /><br />
                                La misión de un Babalawo es ayudar a las personas que a él acuden
                                en busca de respuesta y solución a sus problemas, única y
                                exclusivamente guiándose por los mandatos de Orunmila.
                            </Typography> */}
                            <Typography variant="subtitle1" sx={{ pt: 4, textAlign: "left" }}>
                                ORUNMILA:
                            </Typography>
                            <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                                ORUN: el poder de todo lo que está en el espacio.
                            </Typography>
                            <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                                MI: el poder de todo lo que está en el agua.
                            </Typography>
                            <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                                LA: el poder de todo lo que está en la tierra.
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ pt: 4, textAlign: "left" }}>
                                Este Orisha mayor es el gran benefactor de los seres humanos y el
                                dueño del oráculo de ifa. Él es el testigo de la gran creación. Es él
                                quien rige la vida de un Babalawo.
                                <br /><br />
                                Cuando una persona acude a un Sacerdote de ifa (Babalawo) en
                                busca de adivinación, Orunmila, por medio del Oráculo muestra con
                                sus sabios consejos el camino a seguir por el consultado en la
                                búsqueda de la solución de sus dificultades.
                                <br /><br />
                                El día 4 de octubre todos los Babalawos y creyentes en general,
                                rinden moforibale (homenaje) a esta gran Orisha de la adivinación.
                            </Typography>


                        </CardContent>

                    </Box>

                </Card>
            </Grid>
        </Grid>
    );
}