import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
// import religion from "./religion.webp";
import { Grid, useMediaQuery } from '@mui/material';
import Bold from '../Bold';

export default function Religion() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container id="religion" sx={{ mt: 3 }}>
            <Grid item xs={12} justifyContent="center" sx={{ display: 'flex' }}>
                <Card sx={{ display: 'flex', width: 930, flexDirection: 'column' }} elevation={0}>
                    {/* <CardMedia
                        component={matches ? "img" : "div"}
                        sx={matches ? { width: "100%" } : { height: 450 }}
                        image={religion}
                        alt="La religion"
                    /> */}

                    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>

                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Box sx={{
                                position: 'absolute',
                                width: '400px',
                                height: '346px',
                                left: matches ? '500px' : '-100px',
                                top: '133px',
                                background: 'radial-gradient(72.77% 72.77% at 50% 50%, #c67f650d 0%, rgba(255, 255, 255, 0) 100%)',
                                borderRadius: '50%',
                                WebkitFilter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
                            }} />

                            <Box sx={{
                                position: 'absolute',
                                width: '281px',
                                height: '248px',
                                left: '119px',
                                top: matches ? '670px' : '780px',
                                background: 'radial-gradient(72.77% 72.77% at 50% 50%, #784b2d0d 0%, rgba(255, 255, 255, 0) 100%)',
                                borderRadius: '50%',
                                WebkitFilter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
                            }} />
                            <Typography variant={matches ? "h3" : "h4"} sx={{ textAlign: "center" }}>
                                LA RELIGIÓN
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ pt: 4, textAlign: "left" }}>
                                El culto a Orunmila y a las diferentes deidades del panteón religioso Yoruba en Cuba,
                                es el <Bold text="producto del legado cultural" /> que nos dejaron aquellos hombres y mujeres que
                                fueron traídos como mano de obra esclava, durante el período colonial. <Bold text="Esta fe milenaria "/>
                                 procedente de Nigeria, <Bold text="es hoy practicada no sólo por un elevado número de personas " />
                                 en Cuba, sino en América Latina, Estados Unidos y Europa, a través de
                                la emigración y las iniciaciones que se han llevado a cabo a personas procedentes
                                de distintas partes del mundo.

                                <br /><br />
                                <Bold text={'Esta religión no debe interpretarse como un conjunto de "rituales"'} /> hechos con el
                                propósito de conseguir cualquier cosa. Ifa dice que cada persona tiene su destino.
                                Las deidades, sus consejos y ayuda, servirán en este caso para proteger al individuo
                                y guiarlo por el mejor de los caminos.  <Bold text="Las obras, trabajos que se indican
                                durante una consulta, contribuyen a la solución de los problemas" />, contra
                                hechicerías, etc. La realización de dichas
                                ceremonias más un buen comportamiento ante la vida, nos conducirá al éxito.
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ pt: 2, textAlign: "left" }}>
                                ¡Ifa Odara!
                            </Typography>
                            <Typography variant={matches ? "h5" : "h6"} sx={{ pt: 3, textAlign: "left" }}>
                                LOS SANTOS GUERREROS
                            </Typography>
                            <Typography variant="subtitle1" sx={{ pt: 2, textAlign: "left" }}>
                                Elegwá:
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ pt: 2, textAlign: "left" }}>

                                Este orisa es el que abre y cierra las puertas de los caminos de la vida. Es el
                                primero en recibir las ofrendas, ya que su ayuda es vital para que cualquier
                                ceremonia se materialize. Esta deidad se sitúa y se atiende detrás de la puerta
                                de la casa. El día de la semana para atenderlo es el lunes. Este día se le
                                ofrendan algunas de las cosas que a él le gustan, y se le alumbra con una vela
                                o también con tres velas en forma de triángulo.
                            </Typography>
                            <Typography variant="subtitle1" sx={{ pt: 2, textAlign: "left" }}>
                                Oggún:
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ pt: 2, textAlign: "left" }}>
                                Este Orisa come y bebe de todo sin excepción. Las cosas se le ponen igual que a
                                Elegwá. Su bebida preferida es el aguardiente y el lugar ideal para llevarle sus
                                ofrendas es el monte o la línea del ferrocarril. Oggun es el dueño del hierro y
                                patrón de todos los que trabajen con este metal. Protege en intervenciones
                                quirúrgicas. Es un Orisha muy poderoso.

                            </Typography>
                            <Typography variant="subtitle1" sx={{ pt: 2, textAlign: "left" }}>
                                Oshosi:
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ pt: 2, textAlign: "left" }}>
                                Vive junto a Oggún y come con él, pero en materia de bebidas, su preferida es
                                el anís. Oshosi es un gran cazador y protege en asuntos judiciales. Es un Orisa
                                muy Poderoso.

                            </Typography>
                            <Typography variant="subtitle1" sx={{ pt: 2, textAlign: "left" }}>
                                Osun:
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ pt: 2, textAlign: "left" }}>
                                Este Orisa come junto a los demás guerreros. Cuando la persona ya ha recibido
                                mano de Orunmila o Kofá, Osun vive al lado de Orunmila, de lo contrario vive
                                en alto en cualquier lugar de la casa, menos en los dormitorios o el baño. Le
                                encanta el aguardiente, la miel y la manteca de cacao. Nunca debe olvidar
                                atenderlo, pues es un Orisha fundamental.

                            </Typography>
                        </CardContent>

                    </Box>

                </Card>
            </Grid>
        </Grid>
    );
}