import { Box, Grid, Typography } from "@mui/material";
// import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import { grey } from '@mui/material/colors';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function Footer() {
    return (
        <Grid container sx={{ my: 9 }} id="contact">

            <Grid item xs={12} justifyContent="center" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="contact">
                {/* <ContactSupportIcon sx={{ color: grey[700], fontSize: 60, mb: 2 }} /> */}
                <Typography variant="h6" >
                    BABALAWO EN BARCELONA
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", mt: 2 }}>
                    <EmailIcon />
                    <Typography variant="h6" color="text.primary" component="div" sx={{ ml: 1 }} >
                        babalawoenbarcelona@gmail.com
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", mt: 1 }}>
                    <WhatsAppIcon />
                    <Typography variant="h6" color="text.primary" component="div" sx={{ ml: 1 }} >
                        +34 626 596 124
                    </Typography>
                </Box>
                <Typography variant="body1" color="text.secondary" sx={{ mt: 3 }}>
                    ©{new Date().getFullYear()} por Babalawo en Barcelona.
                </Typography>
            </Grid>
        </Grid>

    )
}