import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme";

const Context = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default Context;
