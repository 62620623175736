import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0a0908ff"
    },
    secondary: {
      main: "#edb183ff"

    },
    background: {
      paper: "rgb(239, 239, 239)",
      default: "rgb(239, 239, 239)"
    }
  }
});

export default theme;